import josh from 'assets/images/josh_mercer.jpg'
import david from 'assets/images/david_benson.jpg'
import { useEffect, useState } from 'react'

const Team = () => {
	const [rendered, setRendered] = useState(false)

	useEffect(() => setRendered(true), [])
	return (
		<div
			className={`flex flex-col items-center my-20 transition-all duration-500 ${
				rendered ? 'blur-none' : 'blur-xl'
			}`}>
			<div className="grid">
				<div className="flex space-x-20">
					<div>
						<img src={josh} className="w-[512px]" />
					</div>
					<div className="flex flex-col text-lg">
						<div className="p-5">
							<div className="mb-4 text-xl font-bold">Josh Mercer</div>
							<div className="mb-4 font-bold">Partner</div>
							<div>
								<ul className="min-w-[600px] max-w-[600px] flex flex-col space-y-4">
									<li>
										Josh Mercer is a Founding Partner at Bencer. He works directly with owners,
										CEO’s, COO’s, and CFO’s to optimize their business operations and implement
										strategic process changes.
									</li>
									<li>
										Josh has worked in a variety of industries such as manufacturing, retail,
										technology, defense, finance, science and construction. He has experience with
										start-up, beginning stage, high growth, and turn around companies of all sizes.
										His biggest satisfaction comes from helping teams transform their company into a
										highly functioning and enjoyable business.
									</li>
									<li>
										Prior to sharing his expertise as a consultant, Josh spent 5 years in business
										banking and venture capital, and then 10 years as a CFO/Executive at a high
										growth manufacturing company. Because of his background, Josh has additional
										expertise in commercial finance sourcing, deal structuring, lender negotiating,
										recapitalizations and debt refinancing.
									</li>
									<li>
										Josh has an undergraduate degree from Brigham Young University in Biochemistry
										and an MBA with an emphasis in Finance from the University of Utah.
									</li>
									<li>
										Josh is blessed with an amazing wife, 4 handsome boys, and 1 beautiful daughter.
										In his spare time he enjoys hunting, camping, kickboxing, car projects and
										participating in whatever activity his family wants to do.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="flex mt-20 space-x-20">
					<div>
						<img src={david} className="w-[512px]" />
					</div>
					<div className="flex flex-col text-lg">
						<div className="p-5">
							<div className="mb-4 text-xl font-bold">David Benson</div>
							<div className="mb-4 font-bold">Partner</div>
							<div>
								<ul className="min-w-[600px] max-w-[600px] flex flex-col space-y-4">
									<li>
										David (Dave) Benson is a CFO and Founding Partner at Bencer Business Advisors
										who helps entrepreneurs and CEOs to start, grow, streamline, or restructure
										their companies. Using cash generation/preservation and removing
										bottlenecks/constraints as primary tactics to turnaround and grow companies with
										consistent and significant success.
									</li>
									<li>
										Dave has experience working in many industries including technology, aerospace,
										manufacturing, construction, real estate development, sports and entertainment,
										intellectual property, and smart homes. Since he has worked as a CEO, CFO and
										COO, he brings a new definition to business consulting. With his experience, he
										is able to advise CEOs who typically are “alone at the top” and could use a
										sounding board with no agenda internal to the company.
									</li>
									<li>
										Before becoming a consultant at Bencer in 2019, Dave started his career at
										Deloitte for 4 plus years. He then served in senior financial and executive
										positions for the past 27 years including Controller, Director of Finance, CFO,
										General Manager, COO, President, CEO and Chairman. His expertise is in creating
										organizational efficiencies, strong fiscal management, turnarounds and
										successful new companies through identification and exploitation of core
										competencies. Concurrently he has worked closely with interfaith leaders,
										community agencies like the United Way and Red Cross, as well as local and
										national non-profits to grow platforms for charitable service and built bridges
										fostered relationships between unlikely partners to improve the wellbeing of the
										poor and disadvantaged.
									</li>
									<li>
										Dave has a Business Administration degree with an emphasis in accounting from
										Cal State Northridge.
									</li>
									<li>Dave enjoys off roading and golfing with his 6 sons.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Team
