import bencerLogo from 'assets/images/bencer-transparent.png'
import linkedIN from 'assets/images/linkedIN.png'
const Footer = () => (
	<div className="flex items-center justify-center text-white space-x-14 bg-bencer-primary-600">
		<img src={bencerLogo} className="w-[200px] top-0 left-0 p-2 m-2" />
		<div className="flex flex-col">
			<div className="flex space-x-4">
				<span className="text-xl font-semibold">{`(801)473-6422`}</span>
				<a
					className="rounded"
					rel="noreferrer"
					target="_blank"
					href="https://www.linkedin.com/company/bencer-business-advisors/about/">
					<img src={linkedIN} width={25} />
				</a>
			</div>
			<a
				className="text-blue-400"
				href="mailto:info@bencerbusinesadvisors.com?subject=Additional Information Request"
				rel="noreferrer"
				target="_blank">
				info@bencerbusinesadvisors.com
			</a>
		</div>
	</div>
)

export default Footer
