import { useEffect, useRef, useState } from "react";

import advisoryImg from "assets/images/advisory.jpg";
import planning from "assets/images/planning.jpg";
import statistics from "assets/images/statistics.jpg";
import Carousel from "components/Carousel";

import hero1 from "assets/images/hero1.jpg";

const Chevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 text-slate-800"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
  </svg>
);

/** Main landing page for the app */
const Landing = () => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => setRendered(true), []);

  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={`min-w-screen min-h-screen transition-all duration-500 ${
        rendered ? "blur-none" : "blur-xl"
      }`}
    >
      <div className="relative grid min-h-[300px] w-full items-center justify-center overflow-clip py-10 text-center">
        <Hero1 />
      </div>
      <div className="flex flex-col items-center justify-center space-y-10 border-t-4 border-bencer-primary-600 bg-white bg-cover py-4">
        <div className="text-2xl font-semibold text-slate-800 ">
          Client{" "}
          <span className="text-blue-500 transition-colors hover:text-blue-400">
            <a
              href="https://www.google.com/search?q=bencer+buisness+advisors&rlz=1C1ONGR_enUS1016US1016&oq=bencer+buisness+advisors&aqs=chrome..69i57j33i10i160l4.4861j0j7&sourceid=chrome&ie=UTF-8#lrd=0xab20fd79ed50ee15:0x543961f12cecf007,1,,,"
              target="_blank"
              rel="noreferrer"
            >
              Google Reviews
            </a>
          </span>
        </div>
        <Carousel />
        <div className="my-5 flex flex-col items-center space-y-5">
          <p className="w-1/2 border-2 bg-white p-4 text-left text-base font-semibold shadow-md">
            Welcome to Bencer Business Advisors, a consulting firm that helps
            companies focus on cash generation and risk mitigation. Our
            consultants will assist your organization in implementing practical
            solutions based off decades of experience.
          </p>
          <div className="grid grid-cols-3 justify-center space-x-10">
            <p className="flex flex-col items-center justify-center border-2 bg-white p-4 text-center text-xl font-semibold shadow-md">
              <span>Turnaround and Restructure</span>
              <span className="text-base font-normal text-slate-700">
                Expert Guidance
              </span>
            </p>
            <p className="flex flex-col items-center justify-center border-2 bg-white p-4 text-center text-xl font-semibold shadow-md">
              <span>Strategic Planning</span>
              <span className="text-base font-normal text-slate-700">
                Grow Your Business
              </span>
            </p>
            <p className="flex flex-col items-center justify-center border-2 bg-white p-4 text-center text-xl font-semibold shadow-md">
              <span>Cash Flow Analysis</span>
              <span className="text-base font-normal text-slate-700">
                Achieve Your Goals
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white pb-20">
        <div className="flex flex-col items-center justify-center space-y-4 p-10">
          <div className="self-start p-4 text-5xl font-bold text-slate-800">
            Bencer Services
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4" ref={scrollRef}>
          <div className="flex flex-col border-2 bg-white">
            <img src={advisoryImg} />
            <div className="p-4">
              <div className="self-start text-xl text-slate-800">
                Free Introductory Consultation
              </div>
              <p>
                Looking to develop your business but not sure where to turn?
                Need help planning or executing your next project? Let us guide
                you.
              </p>
            </div>
          </div>
          <div className="flex flex-col border-2 bg-white">
            <img src={planning} />
            <div className="p-4">
              <div className="self-start text-xl text-slate-800">
                Cash Flow Planning and Analysis
              </div>
              <p>
                At Bencer Business Advisors, we combine our insights and skills
                to transform your company through:
              </p>
              <ul className="mx-6 mt-4">
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Cash and Profit Optimization</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Cost Efficiency Analysis</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Budgets and Fiscal Controls</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Inventory Management</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Supplier and Sales Channel Optimization</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Turn Around Scenarios</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Custom Value-Add Projects</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col border-2 bg-white">
            <img src={statistics} />
            <div className="p-4">
              <div className="self-start text-xl text-slate-800">
                Strategic Planning
              </div>
              <p>We can help you meet future goals with:</p>
              <ul className="mx-6 mt-4">
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Debt Sourcing and Refinancing</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Equity Raises</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Capital Restructures</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Mergers and Acquisitions</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Exit Strategy and Preparation</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Benefits/Payroll Advising</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Information Systems Implementation</span>
                </li>
                <li className="flex items-center space-x-4 text-slate-800">
                  <span>
                    <Chevron />
                  </span>
                  <span>Professional Leadership Development</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

const Hero1 = () => (
  <>
    <img
      src={hero1}
      className="absolute top-1/2 left-1/2 min-w-[1200px] -translate-x-1/2 -translate-y-2/3"
    />
    <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col font-extrabold text-white">
      <p className="m-4 text-4xl xl:text-5xl">
        Cash Optimization and Business Services
      </p>
      <p className="m-4 max-w-[1200px] text-2xl xl:text-3xl">
        Bencer Business Advisors helps businesses like yours achieve lasting
        success through tailor-made practical solutions.
      </p>
    </div>
  </>
);
