const HoveringContactUs = () => (
	<a
		className="fixed z-50 bottom-20 group w-16 h-16 hover:w-[210px] duration-700 rounded-full right-20 transform flex items-center p-3 px-5 space-x-3 font-bold text-white bg-bencer-primary-400 shadow-lg hover:scale-[1.02] transition-all active:scale-100"
		href="mailto:josh@bencerbusinessadvisors.com?subject=I'd like to connect!"
		rel="noreferrer"
		target="_blank"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="absolute w-8 h-8 transition-opacity duration-700 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 group-hover:opacity-0"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={2}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
			/>
		</svg>
		<p className="transition-all duration-700 opacity-0 group-hover:opacity-100 whitespace-nowrap overflow-clip">
			Send us an Email
		</p>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="w-4 h-4 animate-bounce-right"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={3}
		>
			<path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
		</svg>
	</a>
);

export default HoveringContactUs;
