import { Link, NavLink } from "react-router-dom";
import bencerLogo from "assets/images/bencer-transparent.png";

const TopNav = () => {
  return (
    <nav className="fixed top-0 z-50 flex items-center justify-center w-full h-16 font-semibold text-white animate-fade-in">
      <div className="flex items-center justify-center w-full h-16 shadow-md bg-bencer-primary-600">
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex items-center justify-center w-full h-full space-x-10">
            <Link to="/">
              <img src={bencerLogo} width={110} className="h-full m-2 mr-20" />
            </Link>
            <NavLink
              className={({ isActive }) =>
                `whitespace-nowrap text-lg transition-all ${
                  isActive && "border-b-2 border-white"
                }`
              }
              to="/home"
            >
              Home
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `whitespace-nowrap text-lg transition-all ${
                  isActive && "border-b-2 border-white"
                }`
              }
              to="/testimonials"
            >
              Testimonials
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `whitespace-nowrap text-lg transition-all ${
                  isActive && "border-b-2 border-white"
                }`
              }
              to="/about"
            >
              About Us
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `whitespace-nowrap text-lg transition-all ${
                  isActive && "border-b-2 border-white"
                }`
              }
              to="/team"
            >
              Our Team
            </NavLink>
            <a
              className="px-6 py-2 ml-2 text-sm font-bold bg-white rounded-lg text-bencer-primary-600"
              href="mailto:josh@bencerbusinessadvisors.com?subject=I'd like to connect!"
              rel="noreferrer"
              target="_blank"
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default TopNav;
