import ESL from 'assets/images/testimonials/iu.gif'
import BB from 'assets/images/testimonials/BB-Social-Sharing.jpg'
import Alpine from 'assets/images/testimonials/alpine.png'
import cook from 'assets/images/testimonials/Cook LOGO NEW.jpg'
import { useEffect, useState } from 'react'

const Testimonials = () => {
	const [rendered, setRendered] = useState(false)

	useEffect(() => setRendered(true), [])
	return (
		<div
			className={`flex items-center justify-center min-h-screen py-10 bg-cover min-w-screen transition-all duration-500 ${
				rendered ? 'blur-none' : 'blur-xl'
			}`}>
			<div className="grid items-center justify-center w-1/2 grid-cols-3 gap-y-10">
				<div className="flex items-center justify-center mr-10">
					<img src={ESL} className="col-span-1 w-[256px]" />
				</div>
				<div className="flex flex-col col-span-2 p-5 space-y-10">
					<p className="text-xl">{`"Great to work with. I have been working with Bencer for several years and they have helped us grow with the right financial institution at each step of our growth."`}</p>
					<p className="text-sm font-semibold">Mike Gleeson - ESL Vision</p>
				</div>
				<div className="mr-10">
					<img src={BB} className="col-span-1 w-[512px]" />
				</div>
				<div className="flex flex-col col-span-2 p-5 space-y-10">
					<p className="text-xl">{`"I was most fortunate to have come into contact with Bencer Business Advisors in 2019. Bencer Business Advisors has been very helpful in taking the time to gain a full understanding of cash flow planning services and weekly reporting. Josh has been a key player in the financial planning and implementation of crucial business process flows like creating investor reports, inventory analysis, and weekly cash flow reporting. Week after week we have been very satisfied with their prompt communication and business acumen. After 3 years with terrific results, I would not hesitate to contact them."`}</p>
					<p className="text-sm font-semibold">Lauren Guadet - Ball and Buck</p>
				</div>
				<div className="mr-10">
					<img src={Alpine} className="col-span-1 w-[512px]" />
				</div>
				<div className="flex flex-col col-span-2 p-5 space-y-10">
					<p className="text-xl">{`"Great advisors, walked me through processes to help my business and lighten the daily workload. Would recommend and will be using again."`}</p>
					<p className="text-sm font-semibold">JR Lym - Alpine XC</p>
				</div>
				<div className="mr-10">
					<img src={cook} className="col-span-1 w-[512px]" />
				</div>
				<div className="flex flex-col col-span-2 p-5 space-y-10">
					<p className="text-xl">{`"I’ve had the opportunity to work with Bencer Business Advisors for close to two years now and was referred to them when searching for help in the consulting department. I can testify that working with them has been one of, if not the best thing to happen to Cook Builders. They have shared 15+ years of knowledge and experience with me and my team over the past two years, giving my company a massive leap forward in all departments. From daily accounting entries to cash flow charts and HR, Bencer has helped Cook Builders progress from a “Ma and Pa” company to a fine-tuned, well-oiled machine. There is no task too great or too small for them.  I would not hesitate for a second in giving them the majority credit for the immense amount of growth and progression of my company. They have been incredibly hands on while still being sensitive and aware to not overstep any boundaries. I will continue to work with them for the years to come and recommend that everyone else do the same."`}</p>
					<p className="text-sm font-semibold">Josh Brown - Cook Builders</p>
				</div>
			</div>
		</div>
	)
}
export default Testimonials
