import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// pages
import Landing from "components/pages/Landing";
import TopNav from "components/navigation/TopNav";
import Team from "components/pages/Team";
import HoveringContactUs from "components/navigation/HoveringContactUs";
import AboutUs from "components/pages/AboutUs";
import Testimonials from "components/pages/Testimonials";
import { useEffect } from "react";
import Footer from "components/navigation/Footer";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <HoveringContactUs />
      <TopNav />
      <main className="relative mt-16 flex flex-col">
        <div className="max-w-[1920px] self-center">
          <Routes>
            <Route path="/home" element={<Landing />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/team" element={<Team />} />

            {/* No matched route, reroute to landing */}
            <Route path="*" element={<Navigate replace to="/home" />} />
          </Routes>
        </div>
        {location.pathname.includes("about") ? (
          <div className="fixed bottom-0 w-full">
            <Footer />
          </div>
        ) : (
          <Footer />
        )}
      </main>
    </>
  );
}

export default App;
