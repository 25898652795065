import ESL from "assets/images/testimonials/iu.gif";
import BB from "assets/images/testimonials/BB-Social-Sharing.jpg";
import Alpine from "assets/images/testimonials/alpine.png";
import cook from "assets/images/testimonials/Cook LOGO NEW.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

const INCREMENT = 216;
const INIT_TRANSLATION_X = [-INCREMENT * 4, -INCREMENT * 3];
const INTERVAL = 4000;

export default function Carousel() {
  const [translationX, setTranslationX] = useState([...INIT_TRANSLATION_X]);

  const incrementTranslationX = useCallback(() => {
    setTranslationX((prev) => [prev[0] + INCREMENT, prev[1] + INCREMENT]);
  }, [translationX]);

  //loop interval
  useEffect(() => {
    let count = 1;

    function resetTranslation() {
      count = 0;
      setTranslationX([...INIT_TRANSLATION_X]);
    }
    const interval = setInterval(() => {
      if (count && count % 2 === 0) {
        //* the condition
        resetTranslation();
      } else {
        count += 1;
        incrementTranslationX();
      }
    }, INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex max-w-xl space-x-10 overflow-hidden border-x-2 border-slate-300 p-5">
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="relative text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={ESL}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={BB}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={Alpine}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={cook}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="relative text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={ESL}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={BB}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={Alpine}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={cook}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="relative text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={ESL}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={BB}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={Alpine}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
        <motion.div animate={{ x: translationX }} whileHover={{ scale: 1.1 }}>
          <Link to="/testimonials" className="text-center">
            <div className="flex min-h-[128px] items-center justify-center">
              <img
                className="mx-6 w-[128px] shadow-lg "
                src={cook}
                alt="candidate 1"
              />
            </div>
            <p className="m-4 whitespace-nowrap rounded p-1 text-xl font-semibold text-yellow-500 shadow-md">
              ★★★★★
            </p>
          </Link>
        </motion.div>
      </div>
    </>
  );
}
