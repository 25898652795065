import bencerLogo from "assets/images/bencer-transparent.png";
import { useEffect, useState } from "react";

const AboutUs = () => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => setRendered(true), []);
  return (
    <>
      <div
        className={`h-screen w-screen text-white transition-all duration-500 ${
          rendered ? "blur-none" : "blur-xl"
        }`}
      />
      <div
        className={`fixed top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center space-x-20 p-10 text-lg transition-all duration-500 ${
          rendered ? "blur-none" : "blur-xl"
        }`}
      >
        <img
          src={bencerLogo}
          className="max-h-[512px] bg-bencer-primary-600 p-10"
        />
        <p className="flex max-h-[512px] min-w-[500px] items-center justify-center bg-white p-5">
          Bencer Business Advisors exists to bring success and excitement to
          business owners and management teams. Our greatest satisfaction is to
          help teams bring about the change necessary to transform their
          business. Over the years, an increasing amount of companies have been
          struggling with cash. Many of those companies have healthy sales but
          still are tight on payroll. Josh and Dave have been sharing their
          years of executive experience to help companies build cash, lower
          risk, cultivate culture and thrive.
        </p>
      </div>
    </>
  );
};
export default AboutUs;
